/**
 * @file Manages the Alert stateless component.
 * @see https://github.com/schiehll/react-alert-template-basic as sources
 */

import * as React from 'react'
import { domToolkit } from '@domoscio/domoscio-sdk-js'

function BaseIcon({ children, ...props }) {
  const color = props?.color
  const pushRight = props?.pushRight || true
  return React.createElement(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      width: '24',
      height: '24',
      viewBox: '0 0 24 24',
      fill: 'none',
      stroke: color,
      strokeWidth: '2',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      style: {
        marginTop: pushRight ? '0' : '5px',
        marginRight: pushRight ? '20px' : '0',
        minWidth: 24
      }
    },
    children
  )
}

function InfoIcon() {
  return React.createElement(
    BaseIcon,
    { color: domToolkit.domTextInfo.color },
    React.createElement('circle', { cx: '12', cy: '12', r: '10' }),
    React.createElement('line', { x1: '12', y1: '16', x2: '12', y2: '12' }),
    React.createElement('line', { x1: '12', y1: '8', x2: '12', y2: '8' })
  )
}

function SuccessIcon() {
  return React.createElement(
    BaseIcon,
    { color: domToolkit.domTextSuccess.color },
    React.createElement('path', { d: 'M22 11.08V12a10 10 0 1 1-5.93-9.14' }),
    React.createElement('polyline', { points: '22 4 12 14.01 9 11.01' })
  )
}

function ErrorIcon() {
  return React.createElement(
    BaseIcon,
    { color: domToolkit.domTextDanger.color },
    React.createElement('circle', { cx: '12', cy: '12', r: '10' }),
    React.createElement('line', { x1: '12', y1: '8', x2: '12', y2: '12' }),
    React.createElement('line', { x1: '12', y1: '16', x2: '12', y2: '16' })
  )
}

function CloseIcon() {
  return React.createElement(
    BaseIcon,
    { color: '#A7A7A7', pushRight: false },
    React.createElement('line', { x1: '18', y1: '6', x2: '6', y2: '18' }),
    React.createElement('line', { x1: '6', y1: '6', x2: '18', y2: '18' })
  )
}

const alertStyle = {
  padding: '10px 30px',
  borderSize: '1.5px',
  borderStyle: 'solid',
  borderRadius: '30px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
  fontFamily: 'Arial',
  width: '500px',
  boxSizing: 'border-box',
  ...domToolkit.domTextBold
}

const buttonStyle = {
  marginLeft: '20px',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  color: '#FFFFFF'
}

const infoStyle = {
  ...domToolkit.domBgWhite,
  ...domToolkit.domTextInfo,
  ...domToolkit.domBorderInfo
}

const successStyle = {
  ...domToolkit.domBgSuccessLt,
  ...domToolkit.domTextSuccess,
  ...domToolkit.domBorderSuccess
}

const errorStyle = {
  ...domToolkit.domBgDangerLt,
  ...domToolkit.domTextDanger,
  ...domToolkit.domBorderDanger
}

function AlertTemplate({ ...props }) {
  const { message, options, style, close } = props

  return React.createElement(
    'div',
    {
      style: {
        ...alertStyle,
        ...style,
        ...(options.type === 'info' && infoStyle),
        ...(options.type === 'success' && successStyle),
        ...(options.type === 'error' && errorStyle)
      }
    },
    options.type === 'info' && React.createElement(InfoIcon, null),
    options.type === 'success' && React.createElement(SuccessIcon, null),
    options.type === 'error' && React.createElement(ErrorIcon, null),
    React.createElement('span', { style: { flex: 2 } }, message),
    React.createElement(
      'button',
      { onClick: close, style: buttonStyle },
      React.createElement(CloseIcon, null)
    )
  )
}

export default AlertTemplate
