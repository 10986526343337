/**
 * @file Manages the methods of the ActionTimeline React Component.
 * @author Matthieu Mugnier
 */

import * as React from 'react'
import ActionElement from './ActionElement'
import { withDomToolkit } from '../../../styles/dom-toolkit'
import { domoscioAccess } from '../../../api/domoscio_access'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import ActionLoading from './ActionLoading'
/**
 * Renders the ActionTime
 *
 * @category Screens
 */
class ActionTimeline extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reviews: [],
      minimumLoadingTime: 1000,
      hasLoadedReviews: false
    }
  }

  /**
   * Component initialization
   * @async
   */
  async componentDidMount() {
    // Fetch all student reviews
    // reviews scoped by experince for scorm users
    const { scopedList } = this.props

    domoscioAccess.getNextReviews(scopedList).then(reviews => {
      reviews = reviews.filter(
        review => !(review.status === 'accepted' && review.content_type === 'ContentAction')
      )
      reviews = domUtils.computeReviewsForTimeline(reviews)
      this.setState({ reviews: reviews, hasLoadedReviews: true })
    })
    setTimeout(() => this.setState({ minimumLoadingTime: 0 }), this.state.minimumLoadingTime)
  }

  /**
   * Render the divider by position (left/right)
   */
  displayDivider(position) {
    const { classes, responsive } = this.props
    let containerClass = `${classes.domFlex} ${classes.domFlexMiddle} ${classes.domWidth50} ${classes.domPositionAbsolute} zIndex0 `
    containerClass += `${position === 'right' ? 'left50' : 'left0'}`
    containerClass = responsive
      ? `${classes.domFlex} ${classes.domFlexColumn} ${classes.domPositionAbsolute} ${classes.domPositionTop100} ${classes.domHeight100} zIndex0 `
      : containerClass
    return (
      <div className={containerClass}>
        {Array(4)
          .fill(1)
          .map((el, i) =>
            responsive ? (
              <span
                key={i}
                className={`${classes.domDividerVertical} ${classes.domFlex1} ${classes.domMarginBottom}`}
              ></span>
            ) : (
              <span
                key={i}
                className={`${classes.domDivider} ${classes.domFlex1} ${
                  i === 0 && classes.domMarginLeft
                } ${i !== 3 && classes.domMarginRight}`}
              ></span>
            )
          )}
      </div>
    )
  }

  render() {
    const { classes, responsive, pendingReviews, acceptedChallenges } = this.props
    const { reviews, hasLoadedReviews, minimumLoadingTime } = this.state
    return (
      <div className={`${classes.domFlex}`}>
        {/* Timeline container */}
        <div
          className={`${classes.domFlex} ${classes.domFlex2} ${
            responsive && classes.domFlexColumn
          }`}
        >
          {hasLoadedReviews && minimumLoadingTime === 0 ? (
            <>
              {Object.keys(reviews).map((day, i) => (
                /* Action Element Container */
                <div
                  key={i}
                  className={`${classes.domFlex1} ${
                    responsive ? classes.domFlex : classes.domFlexColumn
                  } ${
                    !responsive &&
                    Object.keys(reviews)[0] === day &&
                    'marginNegative40 tour-home-step2'
                  } ${responsive && classes.domMarginXLargeBottom}`}
                >
                  <div
                    className={`${classes.domFlex} ${classes.domFlexMiddle} ${classes.domFlexCenter} ${classes.domPositionRelative}`}
                  >
                    {/* Divider Left (false for first element) */}
                    {!responsive &&
                      !(Object.keys(reviews)[0] === day) &&
                      this.displayDivider('left')}
                    {/* ActionElement display */}
                    <ActionElement
                      display='element'
                      day={day}
                      reviews={reviews[day]}
                      pendingReviews={pendingReviews}
                      acceptedChallenges={acceptedChallenges}
                      order={i}
                    />
                    {/* Divider Right (false for last element) */}
                    {!(Object.keys(reviews)[Object.keys(reviews).length - 1] === day) &&
                      this.displayDivider('right')}
                  </div>
                  {/* ActionElement label */}
                  <div
                    className={`${classes.domMarginTop} ${classes.domFlexCenter} ${
                      classes.domText18
                    } ${classes.domTextGreyDk200} ${responsive && classes.domMarginLargeLeft}`}
                  >
                    <ActionElement
                      display='label'
                      day={day}
                      reviews={reviews[day]}
                      pendingReviews={pendingReviews}
                      acceptedChallenges={acceptedChallenges}
                      order={i}
                    />
                  </div>
                </div>
              ))}
              {/* Optional blank space */}
              {Array(4 - Object.keys(reviews).length)
                .fill(0)
                .map((zero, i) => (
                  <div key={i} className={classes.domFlex1}></div>
                ))}
            </>
          ) : (
            <ActionLoading />
          )}
        </div>
        {/* Right blank space */}
        <div className={classes.domFlex1}></div>
      </div>
    )
  }
}

export default withDomToolkit(ActionTimeline)
