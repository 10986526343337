/**
 * @file Manages the methods of the HomeNav component.
 * @author Matthieu Mugnier
 */

import React from 'react'
import I18n from '../../locales/I18n'
import HomeTour from '../tours/HomeTour'
import {
  IoInformationCircleOutline,
  IoPhonePortraitOutline,
  IoSettingsOutline
} from 'react-icons/io5'
import { Link, useLocation, useMatch, useResolvedPath } from 'react-router-dom'
import { useDomToolkit, Style } from '../../styles/dom-toolkit'
import { Client, User } from '../../contexts/parameters'
import { domUtils } from '@domoscio/domoscio-sdk-js'

/**
 * Renders the HomeNavLink
 */
function HomeNavLink({ children, to, ...props }) {
  const classes = useDomToolkit()
  const styles = getStyles(classes)
  const resolved = useResolvedPath(to)
  const match = useMatch({ path: resolved.pathname, end: true })

  return (
    <>
      <Link
        className={`${styles.HomeNavLink.link(match)} ${
          props.link === 'actions' && 'tour-home-step1'
        }`}
        to={to}
        {...props}
      >
        {children}
        {match && <hr className={styles.HomeNavLink.divider} />}
      </Link>
    </>
  )
}

/**
 * Renders the HomeNavIconLink
 */
function HomeNavIconLink({ children, to, icon, ...props }) {
  const classes = useDomToolkit()
  const styles = getStyles(classes)
  const user = React.useContext(User.State)
  const userDispatch = React.useContext(User.Dispatch)
  const mobileAppActive = user.mobile_app

  const client = React.useContext(Client.State)
  const mailsAuthorized = client.mqb_mailer_daily_reviews
  const userMail = user?.email || false
  const isMailRequired = mailsAuthorized && !userMail

  const actions = user?.actions
  const isUserClickedOnAppIcon = actions?.clicked_on_mobile_app
  const isUserClickedOnSettingsIcon = actions?.clicked_on_settings
  const isDisplayNotifSign =
    (!isUserClickedOnAppIcon && icon === 'app' && !mobileAppActive) ||
    (!isUserClickedOnSettingsIcon && icon === 'settings' && isMailRequired)
  const handleClick = () => {
    const actions = icon === 'app' ? { clicked_on_mobile_app: true } : { clicked_on_settings: true }
    userDispatch({ actions: { ...user.actions, ...actions } })
  }

  return (
    <Link
      onClick={handleClick}
      className={styles.HomeNavIconLink}
      to={to}
      state={{ from: props?.from }}
    >
      {children}
      {isDisplayNotifSign && <div className='notifSign'></div>}
    </Link>
  )
}

/**
 * Renders the Home Navigation
 *
 * @category Components
 */
function HomeNav() {
  const classes = useDomToolkit()
  const location = useLocation()
  const styles = getStyles(classes)
  const client = React.useContext(Client.State)
  const user = React.useContext(User.State)
  const userDispatch = React.useContext(User.Dispatch)
  const links = ['actions', 'progress', 'achievements'].slice(0, -1) // TODO: Remove slice() when achievements is enabled

  const currentDate = () => I18n.strftime(new Date(), I18n.t('date.formats.literary'))

  function fireTour() {
    let tour = {}
    if (location.pathname === '/') {
      tour = { home: false }
    } else if (location.pathname === '/progress') {
      tour = { progress: false }
    }
    userDispatch({
      onboarding: domUtils.updateTour(user.onboarding, 'lxp_lock', tour)
    })
  }

  return (
    <header className='homeScreenHeader'>
      {/* Today's date */}
      <p className={styles.HomeNav.date}>{currentDate()}</p>
      <nav className={classes.domFlex}>
        {/* Left Nav */}
        <div className={styles.HomeNav.leftNav}>
          <HomeTour>
            {links.map((link, i) => (
              <HomeNavLink to={`/${link !== 'actions' ? link : ''}`} key={i} link={link}>
                {I18n.t(`home.${link}`)}
              </HomeNavLink>
            ))}
          </HomeTour>
        </div>
        {/* Right Nav */}
        <div className={styles.HomeNav.rightNav}>
          <HomeNavIconLink to='/settings' from={location.pathname} icon='settings'>
            <IoSettingsOutline color='#828282' />
          </HomeNavIconLink>
          {client?.mobile_app && (
            <HomeNavIconLink to='/download_mobile' from={location.pathname} icon='app'>
              <IoPhonePortraitOutline color='#828282' />
            </HomeNavIconLink>
          )}
          <div className={styles.HomeNavIconLink} style={{ marginLeft: 12 }}>
            <IoInformationCircleOutline
              size={26}
              color='#828282'
              className={classes.domCursorPointer}
              onClick={() => fireTour()}
            />
          </div>
        </div>
      </nav>
    </header>
  )
}

const getStyles = classes =>
  Style.create({
    HomeNavLink: {
      link: match =>
        Style.classes([
          'HomeNavLink',
          classes.domText18,
          classes.domTextGreyDk200,
          match && classes.domTextBold
        ]),
      divider: [
        classes.domBgGreyDk200,
        classes.domWidth60,
        classes.domMarginSmallTop,
        classes.domMarginLeftRemove
      ]
    },
    HomeNavIconLink: [classes.domText22, classes.domTextGreyDk100, classes.domMarginLeft],
    HomeNav: {
      date: [classes.domText11, classes.domTextGreyDk100],
      leftNav: [classes.domFlex, classes.domFlex2],
      rightNav: [classes.domFlex1, classes.domFlexRight, classes.domFlexMiddle]
    }
  })

export default HomeNav
