import React, { useState, useEffect } from 'react'
import { MdWatchLater, MdCheck } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { Question } from '@domoscio/domoscio-ui'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { useDomToolkit } from '../../../../styles/dom-toolkit'
import { getCellTest } from '../../../../api/domoscio_mqb/cell_tests'
import NoChallengeCard from './NoChallengeCard'
import I18n from 'i18n-js'

import styles from './ChallengeCard.module.css'

// Convert a string to qti object
const qti2js = qti => Question.converter.qti2js(qti)

// Calculate day left to realize challenge
const getDayLeft = challenge =>
  domUtils.dayLeft(challenge.accepted_at) + 1 > 7 ? 7 : domUtils.dayLeft(challenge.accepted_at) + 1

// Delete html tags from body. @see https://stackoverflow.com/questions/1499889/remove-html-tags-in-javascript-with-regex
const stripHtml = string => string.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/g, ' ') // replace nbsp with spaces

/**
 * Display <ChallengeCard />
 *
 * @component
 */
const ChallengeCard = ({ review }) => {
  // States
  const [microAction, setMicroAction] = useState(null)

  // Hooks
  const classes = useDomToolkit()

  // Constants
  const dayLeft = getDayLeft(review)
  const isNew = domUtils.sameOrPreviousDay(new Date(), new Date(review.accepted_at)) // 👈 Review is created today

  // Load content on mount
  useEffect(() => {
    if (review.qti) {
      const action = qti2js(review.qti)
      const title = action.heading
      const description = stripHtml(action.statement)
      setMicroAction({ title, description, dayLeft, qti: review.qti })
    } else {
      getCellTest(review.content_url, false).then(qti => {
        const action = qti2js(qti)
        const title = action.heading
        const description = stripHtml(action.statement)
        setMicroAction({ title, description, dayLeft, qti })
      })
    }
  }, [])

  return microAction ? (
    <Link to={'/learning_actions'} state={{ action: { ...microAction, review: review } }}>
      <div className={`${styles.challengeCard}`}>
        {isNew && (
          <div className={`${styles.newTag} ${classes.domBgPrimary}`}>
            {I18n.t('challenges.new')}
          </div>
        )}
        <h6 className={`${classes.domTextPrimary}`}>{microAction.title}</h6>
        <p>{microAction.description}</p>
        <div
          className={`${styles.dayLeft} ${
            microAction.dayLeft === 1 ? classes.domTextDanger : classes.domTextGreyDk200
          }`}
        >
          <MdWatchLater />
          <span className={classes.domMarginSmallLeft}>
            {I18n.t('challenges.nb_day_remaining', {
              nb: microAction.dayLeft,
              count: microAction.dayLeft
            })}
          </span>
        </div>
        <div className={`${styles.challengeButton} ${classes.domTextGreyDk200}`}>
          <MdCheck size={14} />
          <span className={classes.domMarginSmallLeft}>
            {I18n.t('challenges.i_realised_my_challenge')}
          </span>
        </div>
      </div>
    </Link>
  ) : (
    <NoChallengeCard loading={true} />
  )
}

export default ChallengeCard
