import React, { useLayoutEffect } from 'react'
import { MdClose } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { DomButton, Loader } from '@domoscio/domoscio-ui'
import { computeResults, useMemoryLevels, useReviews } from '../../hooks/useLearningSessionResult'
import { useDomToolkit } from '../../styles/dom-toolkit'
import Badge from '../../components/datas/Badge'
import TsrTour from '../../components/tours/TsrTour'
import ProgressTable from '../../components/datas/ProgressTable'
import CorrectionCard from '../../components/correction/CorrectionCard'
import I18n from '../../locales/I18n'

import '../../styles/LearningSessionResult.scss'

/**
 * @category Screens
 * /learning_sessions/result
 */
const LearningSessionResult = () => {
  // Hooks
  const classes: any = useDomToolkit()
  const navigate = useNavigate()
  const location = useLocation()
  const { results, length } = location.state

  // Custom hooks
  const { memoryLevels } = useMemoryLevels(results)
  const { reviews, isLoading } = useReviews()

  // prevent back button click
  useLayoutEffect(() => {
    if (location?.state?.result) {
      navigate('/')
    }
  }, [])

  // Exit screen function
  const exit = () => navigate('/')

  // Datas
  const session = computeResults(results)

  // Handlers
  const handleCorrectionClick = (index: number) =>
    navigate('/learning_sessions/result/correction', {
      state: { ...location.state, activeIndex: index, from: 'learning_sessions' }
    })

  // Renderers
  return (
    <div className='LearningSessionResult'>
      {/* Navigation */}
      <div className='header'>
        <div className='close' onClick={exit}>
          <MdClose size={32} color='#777' />
        </div>
      </div>
      <TsrTour isReady={!isLoading}>
        {/* Heading */}
        <div className='heading'>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <p
                className={`${classes.domTextCenter} ${classes.domTextBold} ${classes.domText20} ${classes.domMarginSmallTop}`}
              >
                {results.length === length
                  ? I18n.t('learning_session_result.heading.done')
                  : I18n.t('learning_session_result.heading.stop')}
              </p>
              {/* Badges */}
              <div className={`badges tour-tsr-step1 ${classes.domMarginLargeTop}`}>
                {/* Answers */}
                {session.answers.count > 0 && (
                  <Badge
                    type='answers'
                    data={{ value: session.answers.score, scale: session.answers.count }}
                  />
                )}
                {/* Actions */}
                {session.actions.count > 0 && (
                  <Badge
                    type='action'
                    data={{ value: session.actions.accepted, scale: session.actions.count }}
                  />
                )}
                {/* Reviews */}
                {reviews.pending.length !== 0 ? (
                  <Badge data={{ value: reviews.pending.length }} type='pendingReviews' />
                ) : reviews.next.length !== 0 ? (
                  <Badge
                    type='nextReviews'
                    data={{ value: reviews.next.length }}
                    options={{ reviews: reviews }}
                  />
                ) : (
                  <Badge data={{ value: reviews.next.length }} type='noReviews' />
                )}
              </div>{' '}
            </>
          )}
        </div>
        {/* Progress */}
        <section className={`container tour-tsr-step2 ${classes.domMarginLargeTop}`}>
          <ProgressTable results={results} memoryLevels={memoryLevels} />
        </section>
        <section className='container'>
          <p
            className={`correctionLabel ${classes.domTextCenter} ${classes.domTextBold} ${classes.domText22}`}
          >
            {I18n.t('learning_session_result.my_correction')}
          </p>
          <div className='correction'>
            {results.map((result: any, index: number) => (
              <CorrectionCard
                key={index}
                result={result}
                identifier={index}
                onClick={handleCorrectionClick}
              />
            ))}
          </div>
        </section>
      </TsrTour>
      {/* Footer */}
      <div className='footer'>
        {/* <DomButton variant='secondary' onClick={() => console.log('building')}>
          {I18n.t('learning_session_result.footer.results')}
        </DomButton> */}
        <DomButton onClick={() => exit()}>
          {I18n.t('learning_session_result.footer.home')}
        </DomButton>
      </div>
    </div>
  )
}

export default LearningSessionResult
