/* eslint-disable */

import React from 'react'
import { domoscioAccess } from '../../api/domoscio_access'
import { updateUserJWT } from '../../storage/jwt'

// Context
const State = React.createContext()
const Dispatch = React.createContext()

const init = {}

// Reducer
const reducer = (state, nextState) => {
  const newState = {
    ...state,
    ...nextState
  }

  if (Object.keys(nextState).includes('onboarding')) {
    domoscioAccess.updateUserParameter({ onboarding: nextState.onboarding })
  }
  updateUserJWT(newState)

  return newState
}

// Provider
const Provider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, init)

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  )
}

// Export
export const User = {
  State,
  Dispatch,
  Provider
}
