import { useState, useEffect } from 'react'
import { domoscioAccess } from '../api/domoscio_access'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { useScormUser } from './useScormUser'
// Custom hook that gets knowledge_nodes.levels
export function useMemoryLevels(results: any[]) {
  const [memoryLevels, setMemoryLevels] = useState<any>({})

  // Fetch the memory levels from API
  async function fetchLevels() {
    const knowledgeNodeIds = results.map((result: any) => result.content.review.knowledge_node_id)

    const levels = await domoscioAccess.getMemoryLevels({
      knowledge_node_id: knowledgeNodeIds,
      group_by: ['knowledge_node_id']
    })

    setMemoryLevels(levels[Object.keys(levels)[0]])
  }

  // Fetch levels on page load
  useEffect(() => {
    fetchLevels()
  }, [])

  return { memoryLevels }
}

// Custom hook that gets student reviews states
export function useReviews() {
  // reviews scoped by experince for scorm users
  const scopedList = useScormUser()

  const [reviews, setReviews] = useState<any>({ pending: [], next: [], daysUntilNextReview: 0 })
  const [isLoading, setIsLoading] = useState(true)

  async function fetchNextReviews() {
    const nextReviews = await domoscioAccess.getNextReviews(scopedList)
    const filteredReviews = nextReviews.filter(
      (review: any) => !(review.content_type === 'ContentAction' && review.status === 'accepted')
    )

    const reviewTimeLine = domUtils.computeReviewsForTimeline(filteredReviews)
    //  ignore "next" and empty arrays
    const upcomingReviewByDate = Object.keys(reviewTimeLine)
      .filter(el => el !== 'next')
      .filter(el => reviewTimeLine[el]?.length > 0)

    const nextReviewDate = upcomingReviewByDate.length === 0 ? null : upcomingReviewByDate[0]
    const nextReviewCount = nextReviewDate ? reviewTimeLine[nextReviewDate] : []
    const daysUntilNextReview = nextReviewDate ? domUtils.dayBetween(nextReviewDate) || 1 : null

    setReviews({
      ...reviews,
      next: nextReviewCount,
      daysUntilNextReview
    })
  }

  async function fetchPendingReviews() {
    setIsLoading(true)
    const pendingReviews = await domoscioAccess.getPendingReviews(scopedList)
    const filteredReviews = pendingReviews.filter(
      (review: any) => !(review.content_type === 'ContentAction' && review.status === 'accepted')
    )

    // Fetch the next reviews
    if (filteredReviews.length === 0) {
      await fetchNextReviews()
      setIsLoading(false)
    } else {
      setReviews({ ...reviews, pending: filteredReviews })
      setIsLoading(false)
    }
  }

  // Fetch reviews on page load
  useEffect(() => {
    fetchPendingReviews()
  }, [])

  return { reviews, isLoading }
}

// Badge datasets
const byType = (result: any, type: string) => result.content.type === type

const getScore = (results: any[]) => results.filter(result => result.input.score === 100).length

export const computeResults = (results: any[]) => {
  const actions = results.filter(result => byType(result, 'ContentAction'))
  const questions = results.filter(result => byType(result, 'ContentEvaluation'))

  return {
    answers: {
      score: getScore(questions),
      count: questions.length
    },
    actions: {
      accepted: getScore(actions),
      count: actions.length
    }
  }
}
