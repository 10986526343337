import React, { useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { SettingsPage, DomUILocales } from '@domoscio/domoscio-ui'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { User, Session, Client } from '../../contexts/parameters'

import { domoscioAccess } from '../../api/domoscio_access'
import I18n from '../../locales/I18n'
import toast from 'react-hot-toast'
import './SettingsScreen.scss'

const SettingsScreen = () => {
  const [identifier, setIdentifier] = useState(0)
  const [updateStatus, setUpdateStatus] = useState('')
  const [isLoading, setIsloading] = useState({ lang: false, password: false, email: false })
  const user = React.useContext(User.State)
  const client = React.useContext(Client.State)
  const userDispatch = React.useContext(User.Dispatch)
  const session = React.useContext(Session.State)
  const navigate = useNavigate()

  const resetLoader = () => {
    setIsloading({
      lang: false,
      email: false,
      password: false
    })
  }

  const handleUserUpdate = userInput => {
    setUpdateStatus('')
    setIsloading({
      lang: !!userInput.lang,
      email: !!userInput.email,
      password: !!userInput.password
    })

    // update mail notification case
    if (Object.keys(userInput)[0] === I18n.t('settings.notification.title')) {
      userInput = {
        mail_notification: userInput[I18n.t('settings.notification.title')]
      }
      domoscioAccess
        .updateUserParameter(userInput)
        .then(res => {
          userDispatch(userInput)
          resetLoader()
          // message to user
          toast.success(I18n.t('lxp_hub.settings_screen.user_updated'), {
            position: 'bottom-right',
            duration: 3000
          })
        })
        .catch(err => {
          console.log(err)
          setUpdateStatus(I18n.t('lxp_hub.ettings_screen.error_generic'))
          resetLoader()
        })
        .finally(() => setIdentifier(identifier + 1))
    } else {
      const body = userInput
      if (userInput.email) {
        body.reconfirmation = true
        user.email ? (body.redirect = true) : (body.redirect = false)
      }
      domoscioAccess
        .updateStudent(body)
        .then(res => {
          if (typeof res === 'object') {
            // message to user => "email already used"
            setUpdateStatus(I18n.t('validations.user.email.current'))
            resetLoader()
          } else if (JSON.parse(res)?.message === 'User updated') {
            userDispatch(userInput)
            // update app language
            if (userInput.lang) {
              I18n.locale = userInput.lang
              DomUILocales.locale = userInput.lang
            }
            resetLoader()
            // message to user after update
            const message =
              I18n.t('lxp_hub.settings_screen.user_updated') +
              (userInput.email ? ' ' + I18n.t('onboarding_screen.mail.feedbackText') : '')
            toast.success(message, {
              position: 'bottom-right',
              duration: 3000
            })
          }
        })
        .catch(err => {
          console.log(err)
          setUpdateStatus(I18n.t('lxp_hub.ettings_screen.error_generic'))
          resetLoader()
        })
        .finally(() => setIdentifier(identifier + 1))
    }
  }

  // Clear sessionStorage && redirect to the web login page
  const logoutAction = () => {
    const redirectUrl = domUtils.addhttp(session?.mqb_url)
    domoscioAccess.logout().then(() => {
      // @ts-ignore
      delete window?.customStorage
      window.location.href = redirectUrl
    })
  }

  const isScormUser = session.origin === 'scorm'
  const mailsAuthorized = client.mqb_mailer_daily_reviews

  const options = mailsAuthorized
    ? [{ title: I18n.t('settings.notification.title'), value: user.mail_notification }]
    : []

  let hiddenRows: ('password' | 'email')[] | undefined
  // hide password section for scorm users
  if (isScormUser) {
    if (mailsAuthorized) {
      hiddenRows = ['password']
      // if mails desactivated hide mail section
    } else {
      hiddenRows = ['password', 'email']
    }
  }
  return (
    <div className='SettingsScreen'>
      <div className='close' onClick={() => navigate('/')}>
        <MdClose size={32} color='#777' />
      </div>
      <SettingsPage
        hiddenRows={hiddenRows}
        key={identifier}
        isUpdating={isLoading}
        user={{
          email: user.email,
          language: user.lang
        }}
        bottomMessage={updateStatus}
        onUpdate={handleUserUpdate}
        onLogOut={logoutAction}
        options={options}
      />
    </div>
  )
}

export default SettingsScreen
