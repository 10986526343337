import { useContext, useState, useEffect } from 'react'
import { Client, User } from '../../contexts/parameters'
import './OnboardingScreen.scss'
import I18n from '../../locales/I18n'
import QRLock from '../../assets/onboarding/qr_lock.svg'
import { MdClose, MdEast, MdOutlineCheck } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import QRCode from 'react-qr-code'
import { getStorage } from '../../storage'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { domoscioAccess } from '../../api/domoscio_access'

const OnboardingScreen = () => {
  const [step, setStep] = useState(1)
  const client = useContext(Client.State)
  const navigate = useNavigate()
  const location = useLocation()
  const primaryColor = client.mqb_primary_color
  const userDispatch = useContext(User.Dispatch)
  const user = useContext(User.State)

  const isDownloadMode = location.pathname.includes('download_mobile')

  useEffect(() => {
    if (!user.onboarding?.lxp_lock?.download_screen) {
      userDispatch({
        onboarding: domUtils.updateTour(user.onboarding, 'lxp_lock', {
          download_screen: true
        })
      })
    }
    if (isDownloadMode) {
      // kpi logs (feature tracking)
      domoscioAccess.postKpiLog('StudentMobilePage')
    }
  }, [])

  const getConnectionQrCode = () => {
    const storage = getStorage()

    const token = storage.getItem('accesstoken')
    return (
      <QRCode
        size={256}
        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
        value={token}
        viewBox={'0 0 256 256'}
      />
    )
  }

  const handleNextClick = () => {
    // kpi logs (feature tracking)
    domoscioAccess.postKpiLog('StudentSkipMobile')
    if (step === 1) {
      setStep(2)
    } else {
      domoscioAccess
        .getUserParameter()
        .then((userParams: any) => {
          userDispatch({ ...user, ...userParams, user_parameter: userParams })
        })
        .finally(() => navigate('/'))
    }
  }

  return (
    <div className='onboardingScreen'>
      <div className='title'>
        {I18n.t(`onboarding_screen.title${isDownloadMode ? '_other' : ''}`)}
      </div>
      <div className='content'>
        <div className='steps'>
          <span style={{ backgroundColor: primaryColor }}>
            {step === 1 ? (
              <span style={{ paddingLeft: 1 }}>1</span>
            ) : (
              <MdOutlineCheck style={{ marginBottom: 2 }} />
            )}
          </span>
          <span style={{ backgroundColor: step === 1 ? '#DBDBDB' : primaryColor }}>2</span>
        </div>
        <div className='infoArea'>
          <div className='colLeft'>
            <div style={{ color: primaryColor }} className='textMain'>
              {I18n.t(`onboarding_screen.step${step}.title`)}
            </div>
            <div className='otherText'>{I18n.t(`onboarding_screen.step${step}.content`)}</div>
            {step === 1 && (
              <div style={{ fontWeight: 700 }} className='otherText'>
                {I18n.t(`onboarding_screen.step${step}.content_2`)}
              </div>
            )}
            <button onClick={handleNextClick} style={{ backgroundColor: primaryColor }}>
              {I18n.t(`onboarding_screen.step${step}.button_text`)}
            </button>
          </div>
          <div className='colRight'>
            {step === 1 ? <img className='QRCode' src={QRLock} /> : getConnectionQrCode()}
          </div>
        </div>
      </div>
      {!isDownloadMode && (
        <div className='bottomTextWrapper'>
          <div onClick={handleNextClick} className='bottomText'>
            {I18n.t('onboarding_screen.bottom_text')}
            <MdEast size={15} />
          </div>
        </div>
      )}
      {isDownloadMode && <MdClose className='closeIcon' onClick={() => navigate('/')} />}
    </div>
  )
}

export default OnboardingScreen
