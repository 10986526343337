import React from 'react'
import * as ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'
import './styles/components.scss'
import './core/index.css'
import App from './App'
import Alert from './core/Alert'
import reportWebVitals from './core/reportWebVitals'
import packageConfig from '../package.json'

const env = process.env.REACT_APP_DOMOSCIO_ENV || 'dev'
if (env !== 'dev') {
  Sentry.init({
    debug: true,
    dsn: 'https://866656e918ab4a518cb2c32e59c33042@o1022181.ingest.sentry.io/6105427',
    normalizeDepth: 10,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration({
        blockAllMedia: true,
        maskAllText: false,
        maskAllInputs: false,
        networkDetailAllowUrls: ['*']
      })
    ],
    // beforeSend called immediately before the event is sent to the server
    beforeSend: (event, hint) => {
      const error = hint.originalException as any
      // Don't need to trace "Invariant failed" errors not a real error for our us case => return null,
      if (error?.message === 'Invariant failed') {
        return null
      } else {
        return event
      }
    },
    ignoreErrors: ['change_ua', 'Non-Error exception captured'],
    tracesSampleRate: 1.0,
    environment: env,
    release: 'learner-experience-lock@' + packageConfig?.version || 'dev',
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0
  })
}

export const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <Alert>
      <App />
    </Alert>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
