import { Client, User } from '../contexts/parameters'
import { useContext } from 'react'

export const useDisplayMailModal = () => {
  const client = useContext(Client.State)
  const user = useContext(User.State)

  // 👇 conditions to display email modal
  const mailsAuthorized = client.mqb_mailer_daily_reviews
  const userMail = user?.email || false
  const isUserSeenMailModal = user.onboarding?.lxp_lock?.mail_screen || false

  const isDisplayMailModal = mailsAuthorized && !isUserSeenMailModal && !userMail
  return isDisplayMailModal
}
