/**
 * @file Manages the methods of the ProgressScreen screen.
 * @author Matthieu Mugnier
 */

import * as React from 'react'
import I18n from '../../locales/I18n'
import Loader from '../../components/global/Loader'
import { useDomToolkit } from '../../styles/dom-toolkit'
import { domoscioAccess } from '../../api/domoscio_access'
import { User } from '../../contexts/parameters'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { useScormUser } from '../../hooks/useScormUser'
/**
 * Renders the Progress Screen
 *
 * @category Screens
 */
function ProgressScreen() {
  const user = React.useContext(User.State)
  const userDispatch = React.useContext(User.Dispatch)
  const runTour = !(user?.onboarding?.lxp_lock?.progress === undefined
    ? false
    : user?.onboarding?.lxp_lock?.progress)

  const [chartUrl, setChartUrl] = React.useState(null)
  const [iframeLoading, setIframeLoading] = React.useState(true)
  const classes = useDomToolkit()
  const learningProgramIds = useScormUser()

  // Load chart onMount
  React.useEffect(() => {
    // if scorm user first fetch objectives of learning program than progression chart
    const isLpIdExist = learningProgramIds.learning_program_id
    if (isLpIdExist) {
      domoscioAccess.getObjectives({ learning_program_id: [isLpIdExist] }).then(objectives => {
        const objectiveIds = objectives.map(elem => elem.id)
        loadMemorizeProgressionChart(objectiveIds)
      })
      // not scorm user fetch progression chart
    } else {
      loadMemorizeProgressionChart()
    }
  }, [])

  // Reload chart with tour if context user progress tour change
  React.useEffect(() => {
    if (runTour) {
      loadMemorizeProgressionChart()
      userDispatch({
        onboarding: domUtils.updateTour(user.onboarding, 'lxp_lock', {
          progress: true
        })
      })
    }
  }, [runTour])

  // Get chart url method
  function loadMemorizeProgressionChart(objectiveIds) {
    const body = {
      locale: I18n.locale,
      tour_guide: runTour
    }
    // scope graph for scorm users
    if (objectiveIds) {
      body.objective_ids = objectiveIds
    }
    domoscioAccess.getMemorizeProgressionChart(body).then(response => {
      setChartUrl(response.url)
    })
  }

  return (
    <>
      <main className={`ProgressScreen ${classes.domHeight100}`}>
        {chartUrl ? (
          <>
            <iframe
              src={chartUrl}
              onLoad={() => setIframeLoading(false)}
              sandbox='allow-same-origin allow-scripts allow-popups allow-forms allow-downloads'
              height='100%'
              width='100%'
              style={{
                display: iframeLoading ? 'none' : 'initial'
              }}
            />
            {iframeLoading && <Loader />}
          </>
        ) : (
          <Loader />
        )}
      </main>
    </>
  )
}

export default ProgressScreen
