/**
 * @file Manages the methods of the Loader component.
 * @author Matthieu Mugnier
 */

import * as React from 'react'
import { useDomToolkit } from '../../styles/dom-toolkit'
import loaderSvg from '../../assets/loader.svg'

/**
 * Renders the Loader component
 *
 * @category Components
 */
function Loader() {
  const classes = useDomToolkit()
  return (
    <div className={`${classes.domWidth100} ${classes.domHeight100} ${classes.domFlexPerfect}`}>
      <img style={{ width: '100px' }} src={loaderSvg} />
    </div>
  )
}

export default Loader
