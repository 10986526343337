import { useState, useEffect } from 'react'
import { domoscioAccess } from '../api/domoscio_access'
import * as Sentry from '@sentry/react'
import { getStorage } from '../storage'

// Custom hook that handle LearningSession API interaction
export function useLearningSession() {
  const [learningSession, setLearningSession] = useState<any>(null)

  // Post new LS and store the created object
  async function createLearningSession() {
    const ls = await domoscioAccess.postLearningSession({
      origin: 'lxp-web'
    })

    setLearningSession(ls)
  }

  async function closeLearningSession() {
    domoscioAccess.closeLearningSession({ id: learningSession?.id })
  }

  // Init LS on page load
  useEffect(() => {
    createLearningSession()
  }, [])

  return { learningSession, closeLearningSession }
}

// Custom hook that extract nextQuestion QTI information into the session
export function useNextQuestion(reviews: any, activeIndex: number) {
  const [contents, setContents] = useState<any[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  // Fetch content details & qti through the domoscio api
  async function fetchContent(review: any) {
    const content = {
      id: review?.content_id,
      type: review?.content_type,
      uid: review?.content_uid,
      content_url: review?.content_url,
      qti: review?.qti
    }

    // Case qti is in the content => don't need to make a call to fetch qti
    if (content.qti) {
      const question = { ...content, review: review }
      setContents([...contents, question])

      // Qti is not exist in the content fetch qti
    } else {
      try {
        if (content?.content_url) {
          const qti = await domoscioAccess.getQti({ cell_test_url: content?.content_url })
          const question = { ...content, qti: qti, review: review }
          setContents([...contents, question])
        } else {
          const error = new Error('No cell test url provided')
          error.name = 'Qti error'
          throw error
        }
      } catch (error) {
        const storage = getStorage()
        const accessToken = storage.getItem('accesstoken')
        Sentry.captureException(error, {
          extra: {
            user: accessToken || 'token not found'
          }
        })
        setError(error as Error)
      }
    }
    setIsLoading(false)
  }

  // Prepare next content details
  async function prepareNextContent() {
    const nextIndex = activeIndex + 1
    const nextReview = reviews[nextIndex]

    if (typeof contents[nextIndex] === 'undefined' && nextReview) {
      await fetchContent(nextReview)
    }
  }

  // Fetch first content on page load
  useEffect(() => {
    const activeReview = reviews[activeIndex]
    fetchContent(activeReview)
  }, [])

  return { contents, isLoading, error, prepareNextContent }
}
