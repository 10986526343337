/**
 * @file Manages the methods of the AchievementScreen screen.
 * @author Matthieu Mugnier
 */

import * as React from 'react'
import I18n from '../../locales/I18n'

/**
 * Renders the Achievement Screen
 *
 * @category Screens
 */
function AchievementScreen() {
  return (
    <>
      <main>
        <h2>{I18n.t('screen.achievements')}</h2>
      </main>
    </>
  )
}

export default AchievementScreen
