import React from 'react'
import { MdOutlineDirectionsRun, MdOutlineLiveHelp, MdWatchLater } from 'react-icons/md'
import { useDomToolkit } from '../../styles/dom-toolkit'
import I18n from '../../locales/I18n'

import '../../styles/LearningSessionResult.scss'

type BadgeProps = {
  data: {
    value: string | number
    scale?: string | number
  }
  type: 'answers' | 'action' | 'reviews' | 'pendingReviews' | 'nextReviews' | 'noReviews'
  label?: string | undefined
  options?: {
    [key: string]: any
  }
}

const reviewsType = ['pendingReviews', 'nextReviews', 'noReviews']

const Icon = ({ id }) => {
  const props = { style: { color: '#edf4fa' } }
  switch (id) {
    case 'answers':
      return <MdOutlineLiveHelp {...props} />
    case 'action':
      return <MdOutlineDirectionsRun {...props} />
    case 'reviews':
      return <MdWatchLater {...props} />
    default:
      return <></>
  }
}

const Badge = ({ data, type, label, options }: BadgeProps) => {
  // Custom function specific to nextReviews
  const getNumberOfDays = (reviews: any) => {
    const nbDays = reviews?.daysUntilNextReview
    return I18n.t('learning_session_result.badges.numberOfDays', { nbDays: nbDays, count: nbDays })
  }

  // Constants declaration
  const classes: any = useDomToolkit()
  const family = reviewsType.includes(type) ? 'reviews' : type
  const numberOfDays = options && 'reviews' in options ? getNumberOfDays(options.reviews) : 1

  // Renderers
  return (
    <div
      className={`badge ${family} ${classes.domPositionRelative}  ${classes.domFlex} ${classes.domFlexColumn} ${classes.domFlexMiddle}`}
    >
      <div className={`wrapper ${classes.domFlexPerfect} ${classes.domButtonCircleMedium}`}>
        <div className={`data ${classes.domText50}`}>
          {data.value}
          {data?.scale && <>/{data.scale}</>}
        </div>
        <div
          className={`icon ${classes.domFlex} ${classes.domFlexPerfect} ${classes.domBgGreyLt200} ${classes.domPositionAbsolute} ${family}`}
        >
          <Icon id={family} />
        </div>
      </div>
      <div className={`label ${classes.domTextCenter}`} style={{ width: 150 }}>
        {typeof label === 'undefined'
          ? I18n.t(`learning_session_result.badges.${type}`, { count: data.value }) +
            (type === 'nextReviews' ? numberOfDays : '')
          : label}
      </div>
    </div>
  )
}

export default Badge
