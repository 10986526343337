/**
 * @file Manages the NavigationContainer declaration (app routing) @see https://reactrouter.com/
 * @author Matthieu Mugnier
 */

import * as React from 'react'
import { Routes, Route } from 'react-router-dom'

import ThemeOnStartScreen from '../screens/ThemeOnStartScreen'

import * as LearningSession from '../screens/learning_session'
import HomeScreen from '../screens/HomeScreen'
import ActionScreen from '../screens/home/ActionScreen'
import ProgressScreen from '../screens/home/ProgressScreen'
import AchievementScreen from '../screens/home/AchievementScreen'
import OnboardingScreen from '../screens/OnboardingScreen/OnboardingScreen'
import SettingScreen from '../screens/SettingsScreen'
import UnsubscribeScreen from '../screens/UnsubscribeScreen'
import { Client } from '../contexts/parameters/client'
import { Session, Theme } from '../contexts/parameters'
import { getStorage } from '../storage'
import * as Errors from '../components/Errors'

/**
 * Navigation <Routes> declaration component
 *
 * @category Components
 */
function Navigation() {
  const storage = getStorage()

  // ErrorsScreens color
  const theme = React.useContext(Theme.State)
  const fill = '#' + theme?.domBgPrimary.match(/[0-9A-Fa-f]{6}/g)

  const client = React.useContext(Client.State)
  const message = client?.mqb_subscription_on_start_message
  const session = React.useContext(Session.State)

  const classicWorkflow = !(
    session?.options?.lxp?.theme_started || session?.options?.lxp?.content_unavailable
  )
  // 🚨 classicWorkflow is false display welcome page with message or content unavailable error & clear session storage
  if (!classicWorkflow) {
    storage.clear()
  }

  return classicWorkflow ? (
    <Routes>
      <Route path='/' element={<HomeScreen />}>
        <Route index element={<ActionScreen />} />
        <Route path='progress' element={<ProgressScreen />} />
        <Route path='achievements' element={<AchievementScreen />} />
      </Route>

      <Route path='onboarding' element={<OnboardingScreen />} />
      <Route path='download_mobile' element={<OnboardingScreen />}></Route>
      <Route path='settings' element={<SettingScreen />} />
      <Route path='learning_actions' element={<LearningSession.Action />} />
      <Route path='learning_actions/result' element={<LearningSession.ActionResult />} />
      <Route path='learning_sessions' element={<LearningSession.Player />} />
      <Route path='learning_sessions/result' element={<LearningSession.Result />} />
      <Route path='learning_sessions/result/correction' element={<LearningSession.Correction />} />
      <Route path='unsubscribe' element={<UnsubscribeScreen />} />

      {/* Errors path handlers */}
      <Route path='401' element={<Errors.AccessDeniedScreen fill={fill} />} />
      <Route path='*' element={<Errors.NotFoundScreen fill={fill} />} />
      <Route path='500' element={<Errors.InternalErrorScreen fill={fill} />} />
      <Route path='error' element={<Errors.ErrorScreen fill={fill} />} />
      <Route path='domoscio' element={<Errors.FallbackScreen />} />
    </Routes>
  ) : session?.options?.lxp?.content_unavailable ? (
    <Routes>
      <Route path='/' element={<Errors.ContentUnavailableErrorScreen fill={fill} />} />
    </Routes>
  ) : (
    <Routes>
      <Route path='/' element={<ThemeOnStartScreen content={message} />} />
    </Routes>
  )
}

export default Navigation
