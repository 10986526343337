/**
 * @file Manages the Alert stateless component.
 * @author Matthieu Mugnier
 */

import * as React from 'react'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from './AlertTemplate'

const options = {
  position: positions.TOP_CENTER,
  timeout: 7000,
  offset: '10px',
  transition: transitions.SCALE
}

/**
 * Wrap app with react-alert Provider
 *
 * @category Components
 */
function Alert({ children }) {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      {children}
    </AlertProvider>
  )
}

export default Alert
