import React from 'react'
import { Session } from '../contexts/parameters'

const useScormUser = () => {
  const session = React.useContext(Session.State)
  const isScormUser = session.origin === 'scorm'
  const learningProgram = session.lpId

  if (isScormUser) {
    return { learning_program_id: Number(learningProgram) }
  } else {
    return {}
  }
}

export { useScormUser }
