/**
 * @file Design of the tour tooltip element
 * @author François Thiounn
 */

import * as React from 'react'
import { useDomToolkit } from '../../styles/dom-toolkit'
import I18n from '../../locales/I18n'
import { MdInfoOutline, MdOutlineClose } from 'react-icons/md'

export default function TooltipTemplate({
  continuous,
  index,
  step,
  isLastStep,
  closeProps,
  primaryProps,
  backProps,
  tooltipProps,
  skipProps
}) {
  const classes = useDomToolkit()

  return (
    <div
      className={`tourContainer ${classes.domText12} ${classes.domTextGreyDk200}`}
      {...tooltipProps}
    >
      {step.title && (
        <div className={`${classes.domTextBold} ${classes.domFlex}`}>
          <div className={`${classes.domFlex1} ${classes.domFlex}`}>
            <MdInfoOutline size={15} className={`${classes.domMarginSmallRight}`} />
            <div className={`${classes.domTextGreyDk200}`}> {step.title}</div>
          </div>
          <div className={`${classes.domTextGreyDk100} ${classes.domCursorPointer}`} {...skipProps}>
            <MdOutlineClose size={15} />
          </div>
        </div>
      )}
      <div className={`${classes.domTextCenter} ${classes.domPaddingSmall}`}>{step.content}</div>
      <div className={`${index > 0 && classes.domFlex}`}>
        {index > 0 && (
          <div
            className={`${classes.domTextPrimary} ${classes.domTextBold} ${classes.domCursorPointer} ${classes.domFlex1}`}
            {...backProps}
          >
            {I18n.t('tour.locales.back')}
          </div>
        )}
        {continuous && !isLastStep && (
          <div
            className={`${classes.domTextPrimary} ${classes.domTextBold} ${classes.domCursorPointer} ${index === 0 && classes.domFlexRight}`}
            {...primaryProps}
          >
            {I18n.t('tour.locales.next')}
          </div>
        )}
        {isLastStep && (
          <div
            className={`${classes.domTextPrimary} ${classes.domTextBold} ${classes.domCursorPointer} ${index === 0 && classes.domFlexRight}`}
            {...closeProps}
          >
            {I18n.t('tour.locales.last')}
          </div>
        )}
      </div>
    </div>
  )
}
