import { MdMail, MdClose } from 'react-icons/md'
import I18n from '../../../locales/I18n'
import { useDomToolkit } from '../../../styles/dom-toolkit'
import React, { useState, useContext } from 'react'
import { domoscioAccess } from '../../../api/domoscio_access'
import toast from 'react-hot-toast'
import { User } from '../../../contexts/parameters/user'
import { Loader } from '@domoscio/domoscio-ui'
import { domUtils, domForms } from '@domoscio/domoscio-sdk-js'

interface Props {
  setMountModal: (arg: boolean) => void
}
const MailModal = ({ setMountModal }: Props) => {
  const [alerMessage, setAlertMessage] = useState('')
  const [email, setEmail] = useState('')
  const [isSending, setIsSending] = useState(false)
  const [step, setStep] = useState(1)
  const [isWriting, setIsWriting] = useState(false)

  const user = React.useContext(User.State)
  const userDispatch = useContext(User.Dispatch)

  const classes = useDomToolkit() as any

  const handleClick = () => {
    if (domForms.customValidations.validateEmail(email)) {
      setIsSending(true)
      domoscioAccess
        .updateStudent({
          email,
          redirect: false,
          reconfirmation: true
        })
        .then(res => {
          if (typeof res === 'object') {
            // message to user => "email already used"
            setAlertMessage(I18n.t('validations.user.email.current'))
          } else if (JSON.parse(res)?.message === 'User updated') {
            setStep(2)
          }
        })
        .catch(err => {
          console.log(err)
          toast.error(I18n.t('settings_screen.error_generic'))
        })
        .finally(() => {
          setIsSending(false)
        })
    } else {
      setAlertMessage(I18n.t('validations.user.email.invalid'))
    }
  }
  const handleClickClose = () => {
    if (!user.onboarding?.lxp_lock?.mail_screen) {
      userDispatch({
        email: step === 1 ? undefined : email,
        onboarding: domUtils.updateTour(user.onboarding, 'lxp_lock', {
          mail_screen: true
        })
      })
    }
    setMountModal(false)
  }

  return (
    <div className='modalContainer'>
      <div className='mailModal'>
        <div className={`container container${step}`}>
          {step === 1 ? (
            <div>
              <div className='title'>{I18n.t('onboarding_screen.mail.title')}</div>
              <div className='subText'>{I18n.t('onboarding_screen.mail.subText')}</div>
              <div className={`inputWrapper ${!isWriting && alerMessage ? 'alertBorder' : ''}`}>
                <span>
                  <MdMail color='#a7a7a7' size={24} />
                </span>
                <input
                  onFocus={() => setIsWriting(true)}
                  onBlur={() => setIsWriting(false)}
                  onChange={e => setEmail(e.target.value)}
                  autoFocus
                  type='email'
                />
              </div>
              <div className='alertMessage'>{!isWriting ? alerMessage : ''}</div>
              <button
                disabled={isSending}
                style={{ cursor: isSending ? 'not-allowed' : 'pointer' }}
                onClick={handleClick}
                className={classes.domBgPrimary}
              >
                {isSending ? <Loader /> : I18n.t('global.submit')}
              </button>
            </div>
          ) : (
            <div className='step2'>
              <div className='title'>{I18n.t('onboarding_screen.mail.feedbackText')}</div>
              <div className='subText'>{I18n.t('onboarding_screen.mail.feedbackText2')}</div>
            </div>
          )}

          <MdClose className='closeIcon' onClick={handleClickClose} />
        </div>
      </div>
    </div>
  )
}

export default MailModal
