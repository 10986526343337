/**
 * @file Manages the methods of the HomeScreen screen.
 * @author Matthieu Mugnier
 */

import * as React from 'react'
import HomeNav from '../components/home/HomeNav'
import { Outlet } from 'react-router-dom'
import { useDomToolkit } from '../styles/dom-toolkit'

import '../styles/screens.scss'
import MailModal from './OnboardingScreen/SubComponents/MailModal'
import { useDisplayMailModal } from '../hooks/useDisplayMailModal'

/**
 * Renders the Home Screen
 *
 * @category Screens
 */
function HomeScreen() {
  const isDisplayMailModal = useDisplayMailModal()
  const [mountModal, setMountModal] = React.useState(isDisplayMailModal)

  const classes = useDomToolkit()
  return (
    <div
      className={`HomeScreen ${classes.domHeight100} ${classes.domFlex} ${classes.domFlexColumn}`}
    >
      <HomeNav />
      <div className={`${classes.domMarginTop} ${classes.domFlex1}`}>
        <Outlet />
      </div>
      {mountModal && <MailModal setMountModal={setMountModal} />}
    </div>
  )
}

export default HomeScreen
