import React from 'react'
import { MdClose } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { DomButton } from '@domoscio/domoscio-ui'
import { useMemoryLevels, useReviews } from '../../hooks/useLearningSessionResult'
import { useDomToolkit } from '../../styles/dom-toolkit'
import Badge from '../../components/datas/Badge'
import ProgressTable from '../../components/datas/ProgressTable'
import I18n from '../../locales/I18n'

import '../../styles/LearningSessionResult.scss'

/**
 * @category Screens
 * /learning_sessions/result
 */
const LearningActionResult = () => {
  // Hooks
  const classes: any = useDomToolkit()
  const navigate = useNavigate()
  const location = useLocation()
  const { results } = location.state

  // Custom hooks
  const { memoryLevels } = useMemoryLevels(results)
  const { reviews } = useReviews()

  // Exit screen function
  const exit = () => navigate('/')

  // Handlers
  const handleCorrectionClick = () =>
    navigate('/learning_sessions/result/correction', {
      state: { ...location.state, activeIndex: 0, from: 'learning_actions' }
    })

  // Renderers
  return (
    <div className='LearningSessionResult'>
      {/* Navigation */}
      <div className='header'>
        <div className='close' onClick={exit}>
          <MdClose size={32} color='#777' />
        </div>
      </div>
      {/* Heading */}
      <div className='heading'>
        <p className={`${classes.domTextCenter} ${classes.domTextBold} ${classes.domText20}`}>
          Félicitation
        </p>
        {/* Badges */}
        <div className='badges'>
          {/* Actions */}
          <Badge
            type='action'
            data={{ value: 1, scale: 1 }}
            label={I18n.t('learning_session_result.badges.actionDone')}
          />
          {/* Reviews */}
          {reviews.pending.length !== 0 ? (
            <Badge data={{ value: reviews.pending.length }} type='pendingReviews' />
          ) : reviews.next.length !== 0 ? (
            <Badge
              type='nextReviews'
              data={{ value: reviews.next.length }}
              options={{ reviews: reviews }}
            />
          ) : (
            <Badge data={{ value: reviews.next.length }} type='noReviews' />
          )}
        </div>
      </div>
      {/* Progress */}
      <section className='container'>
        <ProgressTable results={results} memoryLevels={memoryLevels} />
      </section>
      {/* Footer */}
      <div className='footer'>
        <DomButton variant='secondary' onClick={() => handleCorrectionClick()}>
          {I18n.t('learning_session_result.my_challenge')}
        </DomButton>
        <DomButton onClick={() => exit()}>
          {I18n.t('learning_session_result.footer.home')}
        </DomButton>
      </div>
    </div>
  )
}

export default LearningActionResult
