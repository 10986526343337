import React from 'react'

// Context
const State = React.createContext()
const Dispatch = React.createContext()

const init = {}

// Reducer
const reducer = (state, nextState) => {
  const client = {
    ...state,
    ...nextState
  }
  return client
}

// Provider
const Provider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, init)

  return (
    <State.Provider value={state}>
      <Dispatch.Provider value={dispatch}>{children}</Dispatch.Provider>
    </State.Provider>
  )
}

// Export
export const Client = {
  State,
  Dispatch,
  Provider
}
