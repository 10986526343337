import React from 'react'
import { SimpleProgress } from '@domoscio/domoscio-ui'
import { useDomToolkit } from '../../styles/dom-toolkit'
import I18n from '../../locales/I18n'

import '../../styles/LearningSessionResult.scss'

const ProgressTable = ({ results, memoryLevels }) => {
  const classes: any = useDomToolkit()

  // Utils
  const getLevel = (result: any): number => {
    const kn = result.content.review.knowledge_node_id
    const levels = memoryLevels[kn.toString()]
    const level = levels?.memory_level || 0
    return level === 0 ? 0.05 : level
  }

  // Renderers
  return (
    <table id='progress'>
      <thead>
        <tr>
          {['knowledge_graphs', 'knowledge_nodes', 'retention'].map(head => (
            <th key={head}>{I18n.t(`learning_session_result.progress.head.${head}`)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr className='legend'>
          <td></td>
          <td></td>
          <td className='no-border'>
            {['days', 'week', 'weeks', 'months'].map(legend => (
              <span key={legend}>
                {I18n.t(`learning_session_result.progress.legend.${legend}`)}
              </span>
            ))}
          </td>
        </tr>
        {/* Result Row */}
        {results.map((result: any, index: number) => (
          <tr key={index} className='row'>
            <td>{result.props.heading}</td>
            <td className={classes.domTextPrimary} title={result.props?.concept}>
              {result.props?.concept || 'X'}
            </td>
            <td>
              <SimpleProgress value={getLevel(result)} scale={3} labelType='none' />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ProgressTable
