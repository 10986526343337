import React from 'react'
import styles from './AcceptedChallenges.module.css'
import I18n from 'i18n-js'
import ChallengeCard from './subComponents/ChallengeCard'
import { useDomToolkit } from '../../../styles/dom-toolkit'

const AcceptedChallenges = ({ challenges }) => {
  // 👇 common classnames
  const classes = useDomToolkit()
  return (
    <div className={`${styles.challenges} `}>
      <div className={`${styles.tourHomeStep3} tourHomeStep3`}>
        <h2 className={`${classes.domTextGreyDk200}`}>
          {I18n.t('challenges.ongoing_challenges', {
            nb: challenges.length
          })}
        </h2>
        <div className={styles.challengeList}>
          {challenges.map((challenge, index) => (
            <ChallengeCard key={index} review={challenge} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default AcceptedChallenges
