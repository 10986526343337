/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { Question } from '@domoscio/domoscio-ui'
import { useLearningSession } from '../../hooks/useLearningSession'
import { closeMicroAction } from '../../api/adaptive_engine/events'

import '../../styles/LearningSession.scss'

const qti2js = (action: any) => Question.converter.qti2js((action as { qti: string }).qti)

/**
 * @category Screens
 * /learning_actions
 */
const LearningAction = () => {
  // State initialization
  const [activeIndex] = useState(0)
  const [score, setScore] = useState<undefined | number>(undefined)
  const [result, setResult] = useState<any>()

  // Hooks
  const navigate = useNavigate()
  const location = useLocation()
  const { learningSession, closeLearningSession } = useLearningSession()

  // Constants
  const { action } = location.state
  const questionProps: any = action ? qti2js(action) : {}
  const actionProps = {
    ...questionProps,
    options: { status: 'accepted' }
  }

  // Format action to fit post event process
  const contentFromAction = (action: any) => {
    return {
      ...action,
      id: action.review.content_id,
      type: action.review.content_type
    }
  }

  // Exit screen function
  const exit = (isAnswered?: boolean): any => {
    closeLearningSession()
    const isFailed = result?.input?.answers?.includes('failed') // case challenge not performed => return home page
    if (isAnswered && !isFailed) {
      navigate('/learning_actions/result', { state: { results: [result] } })
    } else {
      navigate('/')
    }
  }

  // <Question.onSubmit />
  const handleSubmit = async (answer: any) => {
    const score = answer.answers.includes('difficult')

    setScore(score ? 100 : undefined)

    // Store results
    const result = { input: answer, content: action, props: questionProps }

    closeMicroAction({
      input: answer,
      content: contentFromAction(action),
      learningSession: learningSession
    }).then(() => {
      setResult(result)
    })
  }

  // Renderers
  return (
    <div className='LearningSession Action' data-index={activeIndex}>
      <div className='actionHeader'>
        <Question.QuizHeader onCancel={() => exit(false)} title={actionProps?.concept} />
      </div>
      <section className='container'>
        <Question
          {...actionProps}
          onSubmit={handleSubmit}
          correctionEnabled={true}
          isChallengeValidation={true}
          correctionScore={score}
          isCorrectionMode={score ?? false}
          onNextAction={() => exit(true)}
        />
      </section>
    </div>
  )
}

export default LearningAction
