import React from 'react'
import { useDomToolkit, Style } from '../../../styles/dom-toolkit'

// Component to create blank areas
const BlankPlaceholder = ({ width, height, marginBottom }) => {
  return <div className='linearBackground' style={{ width, height, marginBottom }}></div>
}

const ActionLoading = () => {
  const classes = useDomToolkit()
  const styles = getStyles(classes)

  // Blank placeholder div properties [width, height, marginBottom]
  const lines = [
    ['80px', '80px', '25px'],
    ['134px', '24px', '0%']
  ]

  return (
    <div className={styles.ActionLoading}>
      {lines.map((elem, index) => (
        <BlankPlaceholder key={index} width={elem[0]} height={elem[1]} marginBottom={elem[2]} />
      ))}
    </div>
  )
}

const getStyles = classes =>
  Style.create({
    ActionLoading: [
      'ActionLoading',
      classes.domWidth25,
      classes.domFlexPerfect,
      classes.domFlexColumn
    ]
  })

export default ActionLoading
