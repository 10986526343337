/**
 * @file Manages Home Tour
 * @author François Thiounn
 */

import * as React from 'react'
import I18n from '../../locales/I18n'
import TooltipTemplate from './TooltipTemplate'
import Joyride from 'react-joyride'
import { User } from '../../contexts/parameters'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { useDisplayMailModal } from '../../hooks/useDisplayMailModal'

export default function HomeTour({ ...props }) {
  const user = React.useContext(User.State)
  const userDispatch = React.useContext(User.Dispatch)
  const runTour = !(user?.onboarding?.lxp_lock?.home === undefined
    ? false
    : user?.onboarding?.lxp_lock?.home)
  // 🚨 Wait for closing mail modal before runing tour
  const isDisplayMailModal = useDisplayMailModal()

  const steps = [
    {
      target: '.tour-home-step1',
      title: I18n.t('tour.title.step1'),
      content: I18n.t('tour.home.step1.content'),
      disableBeacon: true,
      placement: 'right'
    },
    {
      target: '.tour-home-step2',
      title: I18n.t('tour.title.step2'),
      content: I18n.t('tour.home.step2.content'),
      disableBeacon: true,
      placement: 'bottom'
    },
    {
      target: '.tourHomeStep3',
      title: I18n.t('tour.title.step3'),
      content: I18n.t('tour.home.step3.content'),
      disableBeacon: true,
      placement: 'bottom'
    }
  ]

  const handleJoyrideCallback = data => {
    const { action, status } = data
    if (status === 'finished' || action === 'reset') {
      userDispatch({
        onboarding: domUtils.updateTour(user.onboarding, 'lxp_lock', { home: true })
      })
    }
  }

  return (
    <>
      <Joyride
        key={runTour}
        run={runTour && !isDisplayMailModal}
        steps={steps}
        continuous={true}
        locale={I18n.t('tour.locales')}
        showSkipButton={true}
        tooltipComponent={TooltipTemplate}
        callback={handleJoyrideCallback}
      />
      {props.children}
    </>
  )
}
