/**
 * @file Manages the methods of the stateless ActionElement component.
 * @author Matthieu Mugnier
 */

import * as React from 'react'
import I18n from '../../../locales/I18n'
import { Link, useNavigate } from 'react-router-dom'
import { MdCheck, MdOutlineAddCircle, MdWatchLater, MdPendingActions } from 'react-icons/md'
import { useDomToolkit } from '../../../styles/dom-toolkit'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { DomButton } from '@domoscio/domoscio-ui'

/**
 * Renders the ActionElement inside ActionTimeline
 *
 * @category Components
 */
const ActionElement = ({ display, day, reviews, pendingReviews, acceptedChallenges, order }) => {
  const classes = useDomToolkit()
  const navigate = useNavigate()
  const loadLearningSession = () => {
    navigate('/learning_sessions', { state: { reviews: pendingReviews, debugData: reviews } })
  }

  return display === 'element' ? (
    <div className='zIndex1'>
      {order === 0 ? ( // first element is always today
        [
          reviews.length === 0 && acceptedChallenges?.length > 0 ? (
            <div
              key={0}
              style={{ backgroundColor: 'white', height: 93, width: 93, marginTop: -14 }}
              className={`${classes.domFlex} ${classes.domFlexMiddle} ${classes.domFlexCenter}`}
            >
              {/* <MdTimelapse */}
              <MdPendingActions
                style={{ marginTop: 14 }}
                className={`${classes.domTextGreyLt200}`}
                size={93}
              />
            </div>
          ) : reviews.length === 0 ? ( // If today and have not pending reviews
            <div
              key={0}
              className={`${classes.domButtonCircleBorder} ${classes.domBgSuccessLt} ${classes.domBorderSuccess} ${classes.domFlex} ${classes.domFlexMiddle} ${classes.domFlexCenter}`}
            >
              <MdCheck className={`${classes.domText50} ${classes.domTextSuccess}`} />
            </div>
          ) : (
            // Have todo pending reviews
            <Link
              key={0}
              to='/learning_sessions'
              state={{ reviews: pendingReviews, debugData: reviews }}
              className={`${classes.domButtonCircle} ${classes.domBgPrimary} ${classes.domFlex} ${classes.domFlexMiddle} ${classes.domFlexCenter}`}
            >
              <p
                className={`${
                  reviews.length.toString().length < 3 ? classes.domText50 : classes.domText30
                }`}
              >
                {reviews.length}
              </p>
            </Link>
          )
        ]
      ) : (
        // Next reviews
        <div className={classes.domPositionRelative}>
          <div
            className={`${classes.domButtonCircleMedium} ${classes.domBgGreyLt100} ${classes.domFlex} ${classes.domFlexMiddle} ${classes.domFlexCenter}`}
          >
            <p className={`${classes.domText40} ${classes.domTextGreyLt200}`}>{reviews.length}</p>
          </div>
          {day === 'next' ? ( // If index of group day is > 3
            <MdOutlineAddCircle
              className={`${classes.domText24} ${classes.domTextGreyLt200} ${classes.domPositionAbsolute} ${classes.domPositionTopRight}`}
            />
          ) : (
            <MdWatchLater
              className={`${classes.domText24} ${classes.domTextGreyLt200} ${classes.domPositionAbsolute} ${classes.domPositionTopRight}`}
            />
          )}
        </div>
      )}
    </div>
  ) : // Display labelling
  order === 0 ? ( // Check if today
    [
      reviews.length === 0 && acceptedChallenges.length > 0 ? (
        <p
          style={{
            textAlign: 'center',
            maxWidth: 170,
            marginLeft: 0
          }}
          key={0}
          className={`${classes.domMarginRemove} ${classes.domTextBold}`}
        >
          {I18n.t('actions.not_up_to_date')}
        </p>
      ) : reviews.length === 0 ? ( // If today and has pending reviews
        <p key={0} className={`${classes.domMarginRemove} ${classes.domTextBold}`}>
          {I18n.t('actions.up_to_date')}
        </p>
      ) : (
        <div
          key={0}
          className={`${classes.domFlex} ${classes.domFlexColumn} ${classes.domFlexMiddle}`}
        >
          <p className={`${classes.domMarginRemove} ${classes.domTextBold}`}>
            {I18n.t('actions.to_do_today')}
          </p>
          <DomButton className={classes.domMarginTop} onClick={() => loadLearningSession()}>
            {I18n.t('actions.lets_go')} &nbsp; &#10140;
          </DomButton>
        </div>
      )
    ]
  ) : (
    [
      // Next reviews
      day !== 'next' && ( // Not showing if index of group day is > 3
        <p key={0} className={`${classes.domMarginRemove} ${classes.domTextBold}`}>
          {I18n.t('actions.in_nb_of_days', {
            nb_days: domUtils.dayBetween(day) || 1,
            count: domUtils.dayBetween(day) || 1
          })}
        </p>
      )
    ]
  )
}

export default ActionElement
