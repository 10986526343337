/**
 * @file Manages the Reactjs Container and init methods.
 * @author Matthieu Mugnier
 */

import './core/App.css'

import * as React from 'react'
import Navigation from './navigation/Navigation'
import Initializers from './core/Initializers'
import { Parameters } from './contexts/parameters'
import { useLocation } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'

function App() {
  const { pathname } = useLocation()

  // 👇  To have a gray bg color on download screen
  const isDownloadMode = pathname.includes('download_mobile') || pathname.includes('onboarding')

  return (
    <Parameters>
      <Initializers>
        <div className={`App domScroll ${isDownloadMode ? 'downloadMode' : ''}`}>
          <Navigation />
          <Toaster />
        </div>
      </Initializers>
    </Parameters>
  )
}

export default App
