import React from 'react'
import { domUtils } from '@domoscio/domoscio-sdk-js'
import { Badge } from '@domoscio/domoscio-ui'
import I18n from '../../locales/I18n'

type Props = {
  result: any
  identifier: number
  onClick: (index: number) => void
}

const CorrectionCard = ({ result, identifier, onClick }: Props) => {
  // Props
  const { input, props } = result
  const handleClick = () => onClick && onClick(identifier)

  // Constants
  const status = input?.score === 100 ? 'success' : 'danger'
  const content = domUtils.cropText(
    domUtils.getTextFromContent(
      props.type === 'holes' ? props.body?.prompt : props?.statement || props?.heading
    ),
    100
  )
  return (
    <div className={`correctionCard ${status}`} onClick={handleClick}>
      <div className='row'>
        <Badge color={status} size='sm'>
          {I18n.t('learning_session_result.answer')}
        </Badge>
        <Badge size='sm'>{props?.tag}</Badge>
      </div>
      <span className='title' title={props?.concept}>
        {props?.concept || 'X'}
      </span>
      <div className='content'>{content}</div>
    </div>
  )
}

export default CorrectionCard
