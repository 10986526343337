import React from 'react'

/**
 * HTML custom render function
 *
 * @return {object} - custom renderers object
 */

const htmlToReactJSX = html => {
  const HtmlToReact = require('html-to-react')
  const HtmlToReactParser = require('html-to-react').Parser

  const isValidNode = function () {
    return true
  }

  // Order matters. Instructions are processed in the order they're defined
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)
  const processingInstructions = [
    {
      // Handle warning: validateDOMNesting(...): <div> cannot appear as a descendant of <p>.
      shouldProcessNode: function (node) {
        return node.name === 'p'
      },
      processNode: function (node, children) {
        if (node.children.map(child => child.type).includes('tag')) {
          return React.createElement('div', {}, children)
        } else {
          return processNodeDefinitions.processDefaultNode(node, children)
        }
      }
    },
    {
      // Custom <img/> processing
      shouldProcessNode: function (node) {
        return node.name === 'img'
      },
      // Resize properly
      processNode: function (node, children) {
        node.attribs.style = 'max-width: 100%; height: 100%;'
        return processNodeDefinitions.processDefaultNode(node, children)
      }
    },
    {
      // Anything else
      shouldProcessNode: function (node) {
        return true
      },
      processNode: processNodeDefinitions.processDefaultNode
    }
  ]

  const htmlToReactParser = new HtmlToReactParser()
  return htmlToReactParser.parseWithInstructions(html, isValidNode, processingInstructions)
}

const ThemeOnStartScreen = ({ content }) => {
  return <div className='ThemeOnStartScreen'>{htmlToReactJSX(content)}</div>
}

export default ThemeOnStartScreen
